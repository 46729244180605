import moment from "moment";

export const showOnlySections = [
  {
    header: "TOP PERFORMING",
    items: [
      { item: 5, label: "Top 5", value: "top5" },
      { item: 10, label: "Top 10", value: "top10" },
      { item: 20, label: "Top 20", value: "top20" },
    ],
  },
  {
    header: "BOTTOM PERFORMING",
    items: [
      { item: 5, label: "Bottom 5", value: "bottom5" },
      { item: 10, label: "Bottom 10", value: "bottom10" },
      { item: 20, label: "Bottom 20", value: "bottom20" },
    ],
  },
];

export const getPeriods = (date: any) => {
  return [
    { label: `Yesterday (${moment(date).format("MM/DD")})`, value: 1 },
    {
      label: `Current Period (${moment(date)
        .startOf("month")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 2,
    },
    {
      label: `Last Period (${moment(date)
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD")} - ${moment(date)
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD")})`,
      value: 3,
    },

    {
      label: `Year To Date (${moment(date)
        .startOf("year")
        .format("MM/DD")} - ${moment(date).format("MM/DD")})`,
      value: 5,
    },
    {
      label: `Trailing 12 (${moment(date)
        .subtract(1, "years")
        .add(1, "days")
        .format("MM/DD/YY")} - ${moment(date).format("MM/DD/YY")})`,
      value: 4,
    },
  ];
};

export const getPeriodDates = (
  id: number,
  date: any = moment().subtract(1, "days")
) => {
  switch (id) {
    case 1: // Yesterday
      return {
        FromDate: moment(date).format("MM/DD/YYYY"),
        ToDate: moment(date).format("MM/DD/YYYY"),
      };

    case 2: // Current Period
      return {
        FromDate: moment(date).startOf("month").format("MM/DD/YYYY"),
        ToDate: moment(date).format("MM/DD/YYYY"),
      };

    case 3: // Last Period
      return {
        FromDate: moment(date)
          .subtract(1, "months")
          .startOf("month")
          .format("MM/DD/YYYY"),
        ToDate: moment(date)
          .subtract(1, "months")
          .endOf("month")
          .format("MM/DD/YYYY"),
      };

    case 5: // Year to Date (YTD)
      return {
        FromDate: moment(date).startOf("year").format("MM/DD/YYYY"),
        ToDate: moment(date).format("MM/DD/YYYY"),
      };

    case 4: // Trailing 12 Months
      return {
        FromDate: moment(date)
          .subtract(1, "years")
          .add(1, "days")
          .format("MM/DD/YYYY"),
        ToDate: moment(date).format("MM/DD/YYYY"),
      };

    case 10: // Last 7 Days
      return {
        FromDate: moment(date).subtract(6, "days").format("MM/DD/YYYY"),
        ToDate: moment(date).format("MM/DD/YYYY"),
      };

    default:
      throw new Error(`Invalid id: ${id}`);
  }
};

export const DashboardsDataList = [
  {
    label: "Portfolio Health",
    description: "A high-level view of your portfolio",
    id: 1,
  },
  { label: "Labor Focus", description: "Dashboard for labor analysis", id: 2 },
  { label: "NE Region", description: "Health of the NE Region", id: 3 },
];

export const BRACHARTCOLORS = [
  `#065A8C`,
  "#FCA147",
  "#875BF7",
  "#B12A18",
  "#0ff",
];


//Common Functions 
export function getShortMonthName(monthNumber) {
  if (monthNumber < 1) {
    return "Invalid month number. Please provide a positive number.";
  }
  const adjustedIndex = (monthNumber - 1) % 12; // Wrap around using modulo
  return moment.monthsShort()[adjustedIndex];
}

export function generateChartDataDummy(
  numPoints,
  numBars = 1,
  chartComparison,
  xLabel,
  chartType,
  Hids: any = []
) {
  const lineChartConfig = (index) =>
    chartType.toLowerCase() === "line" &&
    chartComparison[index]?.value?.toLowerCase() !== "actual"
      ? {
          borderDash: [5, 5], // Makes the line dotted
          fill: false,
        }
      : {};

  const data = {
    labels: Array.from({ length: numPoints }, (_, i) =>
      chartType.toLowerCase() === "line"
        ? getShortMonthName(i + 1)
        : Hids.length === 0
        ? xLabel
        : chartType.toLowerCase() === "bar"
        ? Hids[i]?.value
        : getShortMonthName(i + 1)
    ),
    datasets: Array.from({ length: numBars }, (_, i) => ({
      display: false,
      label: chartComparison[i].value, // Each dataset will have its own label
      data: Array.from({ length: numPoints }, (__, j) => {
        // Example of using a sine wave to generate curved data
        const sineWaveValue =
          Math.sin(((j + 1) * Math.PI) / 5) * 50 + 50 + Hids.length; // Curved data using sine
        return sineWaveValue + i * 20; // Offset by dataset index to give variations between bars
      }),
      backgroundColor: BRACHARTCOLORS[i], // Different color for each bar
      borderColor: BRACHARTCOLORS[i], // Different color for each bar
      ...lineChartConfig(i),
    })),
  };

  return data;
}

export function getFormattedDate(date, periodValue) {
  if (periodValue >= 4) {
    return moment(date).format("MMM YY");
  } else {
    return moment(date).format("ddd M/D");
  }
}

export function generateChartDataFromAPI(apiData, chartType, periodValue) {
  // Function to configure line chart properties
  const lineChartConfig = (type) =>
    chartType.toLowerCase() === "line" && type.toLowerCase() !== "actual"
      ? {
          borderDash: [5, 5], // Makes the line dotted
          fill: false,
        }
      : {};

  const actualData = apiData?.filter(
    (item) => item.type.toLowerCase() == "actual"
  );
  // Extract labels from API data
  const labels = actualData?.map((item, index) =>
    chartType.toLowerCase() === "bar"
      ? item.letterCode
      : getFormattedDate(item.date, periodValue)
  );

  // Group data by `type` to create datasets
  const typeGroups = apiData?.reduce((acc, item) => {
    if (!acc[item.type]) acc[item.type] = [];
    acc[item.type].push(item.dataValue || 0); // Use 0 if `dataValue` is null
    return acc;
  }, {});

  // Create datasets from grouped data
  const datasets = typeGroups
    ? Object?.entries(typeGroups)?.map(([type, values], index) => ({
        label: type, // Use the `type` as the dataset label
        data: values,
        backgroundColor: BRACHARTCOLORS[index], // Assign colors by index
        borderColor: BRACHARTCOLORS[index], // Assign border color by index
        ...lineChartConfig(type),
      }))
    : [];

  // Return the final chart data object
  const data = {
    labels,
    datasets,
  };

  return data;
}

export function isValidChartData(data: any): boolean {
  // Check if 'data' has 'labels' and 'datasets' properties
  if (!data || typeof data !== "object") return false;

  const hasLabels = Array.isArray(data.labels);
  const hasDatasets =
    Array.isArray(data.datasets) &&
    data.datasets.every(
      (dataset: any) =>
        dataset &&
        typeof dataset === "object" &&
        Array.isArray(dataset.data) &&
        typeof dataset.label === "string"
    );

  return hasLabels && hasDatasets;
}

export const ProgressDummyData = {
  actual: 60,
  budget: 100,
};


export function calculatePercentageDifference(actualValue, dataValue) {
  if (actualValue === 0) {
    return 0;
  }
  return (((dataValue - actualValue) / actualValue) * 100).toFixed(2);
}
