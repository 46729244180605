import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import DashboardsListDropdown from "./components/DashboardsListDropdown";
import MetricChart from "./components/MetricChart";
import {
  Responsive as ResponsiveReactGridLayout,
  WidthProvider,
} from "react-grid-layout";
import {
  calculateHeight,
  findEmptyColumnAndAddConfig,
  generateLayout,
  LayoutTypes,
  reverseLayout,
} from "./layoutConfig";
import { PortfolioDashboard } from "../../Common/Services/PortfolioDashboard";
import { getPeriods, showOnlySections } from "./Data/options";
import { PeriodSelector } from "../Analytics/Components/PeriodSelector";
import moment from "moment";
import HorizontalDropdownLayout from "./components/HorizontalDropdownLayout";
import emptyCardImage from "../../Common/Assets/Images/metrics-portfolio.png";
import MetricModal from "./modal/MetricModal";
import { HotelGroupSelector } from "../Analytics/Components/HotelGroupSelector";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { reject } from "q";
import _ from "lodash";
import { Prompt, useHistory } from "react-router-dom";

const ResponsiveGridLayout = WidthProvider(ResponsiveReactGridLayout);

const getItemByValue = (value) => {
  for (const section of showOnlySections) {
    const foundItem = section.items.find((item) => item.value === value);
    if (foundItem) {
      return foundItem.item;
    }
  }
  return null; // Return null if no match is found
};

const moduleName = "Accounting";
// const dashboardId = 1;
function PortfolioDashboardPageLayout(props: any) {
  const [columns, setColumns] = useState(2); // Default to 2-column layout

  const [metrics, setMetrics] = useState<any>([]);
  const [metricsLoading, setMetricsLoading] = useState<any>(false);

  const [layout, setLayout] = useState<any>([]);
  const [originalLayout, setOriginalLayout] = useState<any>([]);
  const [Periods, setPeriods] = useState<any[]>([]);
  const [DashboardDataList, setDashboardDataList] = useState<any[]>([]);
  const [periodValue, setPeriodValue] = useState<any>(1);
  const [editModeOn, setEditModeOn] = useState<any>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalXValue, setModalXValue] = useState<any>(0);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [dashboardDataListLoading, setDashboardDataListLoading] = useState<boolean>(false);
  const [rowHeight, setRowHeight] = useState(130); // Default row height
  const [selectedLayout, setSelectedLayout] = useState(3); // Default row height
  const [dashboardId, setDashboardId] = useState(1); // Default row height
  const [originalselectedLayout, setoriginalSelectedLayout] = useState(3); // Default row height
  const [groupEhid, setGroupEhid] = useState<any>({
    hotelIds: 0,
    subGroupSelectValue: "Group",
    hotelName: "",
  });


  // Update rowHeight dynamically based on screen size
  useEffect(() => {
    const updateRowHeight = () => {
      if (window.innerWidth < 768) {
        setRowHeight(80); // Smaller screens
      } else if (window.innerWidth < 1200) {
        setRowHeight(100); // Medium screens
      } else {
        setRowHeight(130); // Larger screens
      }
    };

    updateRowHeight(); // Initial setting
    window.addEventListener("resize", updateRowHeight); // Update on resize

    return () => {
      window.removeEventListener("resize", updateRowHeight); // Cleanup
    };
  }, []);

  const hideModal = (isReload = false) => {
    setShowModal(false);

    if (isReload) {
      setMetrics([]);
      GetDashboardWidgetMapping(dashboardId);
    }

    setModalXValue(0);
  };

  const handleAddMetric = (xValue = 0) => {
    const layoutType = LayoutTypes[selectedLayout - 1];
    if (!layoutType) {
      throw new Error(`Invalid layoutTypeIndex: ${selectedLayout}`);
    }

    setShowModal(true);
    if (metrics.length == 0) {
      setModalXValue(xValue);
    } else {
      const { cols, partitions } = layoutType;

      // Calculate the proportion of xValue within the total cols
      const proportionX = Math.round((xValue / cols) * 100) / 100;

      // Determine the column index based on partitions
      let cumulativeWidth = 0;
      let columnNo = 0;

      for (let i = 0; i < partitions.length; i++) {
        cumulativeWidth += partitions[i];
        cumulativeWidth = Math.round(cumulativeWidth * 100) / 100;
        if (proportionX < cumulativeWidth) {
          columnNo = i;
          break;
        }
      }

      setModalXValue(Math.round(columnNo));
    }
  };

  useEffect(() => {
    const date = moment(); // Current date
    const Yesterday = date.clone().subtract(1, "days");
    const _periods = getPeriods(Yesterday);
    setPeriods(_periods);
  }, []);

  useEffect(() => {
    const _layout = layout.filter((item) => !item.i.startsWith("empty"));
    const _originalLayout = originalLayout.filter(
      (item) => !item.i.startsWith("empty")
    );
    if (
      !_.isEqual(_layout, _originalLayout) ||
      !_.isEqual(selectedLayout, originalselectedLayout)
    ) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [layout, originalLayout]);

  // Warn before closing/refreshing the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Do you want to save your changes before leaving?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleEditButton = () => {
    setEditModeOn(true);
  };

  const handleCloseEditMode = () => {
    setEditModeOn(false);
  };

  const handleConfirm = (location: any = null) => {
    if (unsavedChanges) {
      confirmAlert({
        title: "Unsaved confirmation",
        overlayClassName: "portfolio-change-alert",
        message:
          "You have unsaved changes. Do you want to save your changes before leaving?",
        buttons: [
          {
            label: "Save & Continue",
            onClick: () => {
              handleSavePositions(() => {
                if (location) {
                  setTimeout(() => {
                    window.location.href = `${location.pathname}`;
                    // props.history.push(location.pathname);
                  }, 100);
                }
              });
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setUnsavedChanges(false);

              if (location) {
                setTimeout(() => {
                  window.location.href = `${location.pathname}`;
                  // props.history.push(location.pathname);
                }, 100);
              } else {
                setLayout(originalLayout);
                setSelectedLayout(originalselectedLayout);
              }
            },
          },
          {
            label: "Cancel",
            onClick: () => {
              reject();
            },
          },
        ],
      });
    }
  };

  const handleCancel = () => {
    //handelCancel
    setUnsavedChanges(false);
    setLayout(originalLayout);
    setSelectedLayout(originalselectedLayout);
    handleCloseEditMode();

    // if (unsavedChanges) {
    //   handleConfirm();
    // } else {
    //   handleCloseEditMode();
    // }
  };

  const handleSavePositions = (callBackLocation = () => {}) => {
    //handle Save

    const reverseLayoutData = reverseLayout(layout, selectedLayout);
    const requestLayout = reverseLayoutData
      .filter((item) => !item.i.startsWith("empty"))
      .map((item: any) => ({
        WidgetId: parseInt(item.i),
        RowId: item.rowNo,
        ColumnId: item.columnNo,
      }));

    const requestData = {
      DashboardId: dashboardId,
      DashboardLayout: selectedLayout,
      WidgetPositions: requestLayout,
    };
    PortfolioDashboard.UpdateWidgetPositions(requestData)
      .then((res) => {
        if (res.success) {
          setOriginalLayout(layout);
          setoriginalSelectedLayout(selectedLayout);
          setUnsavedChanges(false);
          toast.success("Dashbboard Layout updated successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          callBackLocation();
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        handleCloseEditMode();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCustomLayoutChange = (index) => {
    let cardLayout = generateLayout(metrics, index);
    cardLayout = findEmptyColumnAndAddConfig(cardLayout, index);
    setLayout(cardLayout);

    setSelectedLayout(index);
  };

  const GetDashboardMaster = (onPageLoad=true) => {
    setDashboardDataListLoading(true)
    PortfolioDashboard.GetDashboardMaster()
      .then((res: any) => {
        const dashboardList = res
        setDashboardDataList(dashboardList);
        if(onPageLoad)
        {
          const sysDashboard = dashboardList[0];

          setDashboardId(sysDashboard.dashboardId);
          setSelectedLayout(sysDashboard.dashboardLayout ? parseInt(sysDashboard.dashboardLayout) : 3);
          setPeriodValue(sysDashboard.defaultDaterange ? parseInt(sysDashboard.defaultDaterange) : 1);
          setGroupEhid({
            hotelIds:sysDashboard.defaultProperties ? sysDashboard.defaultProperties == 0 ? 0: sysDashboard.defaultProperties : 0,
            subGroupSelectValue: "Group",
            hotelName: "",
          });
        }
        else{

        }
        

        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setDashboardDataListLoading(false)
      });
  };

  useEffect(() => {
    GetDashboardMaster();
  }, []);

  useEffect(() => {
    if (dashboardId) {
      GetDashboardWidgetMapping(dashboardId);
    }
  }, [dashboardId]);

  useEffect(() => {
    if (metrics && selectedLayout) {
      let cardLayout = generateLayout(metrics, selectedLayout);
      cardLayout = findEmptyColumnAndAddConfig(cardLayout, selectedLayout);
      setLayout(cardLayout);
      setOriginalLayout(cardLayout);
    }
  }, [metrics, selectedLayout]);

  const GetDashboardWidgetMapping = (dashboardId) => {
    setMetricsLoading(true);
    PortfolioDashboard.GetDashboardWidgetMapping(dashboardId)
      .then((result) => {
        setMetrics(result);
        const _selectedlayout =
          result?.length > 0
            ? !result[0]?.dashboardLayout
              ? 3
              : result[0]?.dashboardLayout
            : 3;
        setSelectedLayout(_selectedlayout);
        setoriginalSelectedLayout(_selectedlayout);
      })
      .catch((err) => {
        setMetrics([]);
      })
      .finally(() => {
        setMetricsLoading(false);
      });
  };

  const getColumnWidth = () => {
    if (columns === 1) return 12;
    if (columns === 2) return 6;
    return 4;
  };

  const handleSelection = (item: any) => {
    console.log("Selected item:", item);

    // Add Logic here on select Dashboard set default
    // setPeriodValue(item.period)
    // setSelectedLayout(item.layout)

    // setGroupEhid(prev  => ({
    //   ...prev,
    //   hotelIds:item.hids
    // }));

    setTimeout(() => {
      setDashboardId(item.dashboardId);
    }, 0);
  };

  const onCreateDashboard = () => {
    GetDashboardMaster()
  
  };

  const renderEmptyState = (xValue = 0) => {
    return (
      <div className="empty-card-view">
        <h2>Want a Card here</h2>
        <Button
          variant="link"
          disabled={
            layout.filter((item) => !item.i.startsWith("empty")).length == 20
          }
          onClick={() => handleAddMetric(xValue)}
        >
          Add a New One
        </Button>
        <div className="img">
          <img
            src={emptyCardImage}
            alt="Want a Card here"
            className="img-fluid"
          />
        </div>
      </div>
    );
  };

  const renderEmptyMetricNone = () => {
    const { partitions, cols } = LayoutTypes[selectedLayout - 1];

    // Calculate the column width for each partition, based on a 12-column grid system
    const columnWidths = partitions.map((partition) =>
      Math.round(partition * 12)
    ); // Converts partition to grid width (e.g., 0.5 => 6, 0.33333 => 4)

    return (
      <Row key={"empty"} className="add-card-row">
        {/* For debugging, showing the partitions and column widths */}
        {/* <div>{JSON.stringify({ partitions, cols, columnWidths })}</div> */}
        {columnWidths.map((width, index) => (
          <Col key={index} lg={width} md={12}>
            {renderEmptyState(index)}
          </Col>
        ))}
      </Row>
    );
  };

  // Define your renderMetrics with updated props for each grid item
  const renderMetrics = (metric, hids, layoutItem) => {
    if (!metric) {
      return <></>;
    }
    return (
      <div
        key={hids?.length + metric?.title}
        className="grid-item"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {JSON.stringify(layoutItem)} */}
        <MetricChart
          // isDummyData
          // chartComparison={[]}
          chartComparison={metric?.dashboardComparisonWidgetMappingResponse.map(
            (item) => ({
              value: item.comparasionName,
              description: item.comparasionName,
            })
          )}
          // chartComparison={[{ value: "Actual", description: "Actual" }]}
          chartType={metric?.cardType?.toLowerCase()}
          chartTitle={metric?.title}
          metric={{ ...metric?.parameter, hids, ...metric }}
          layoutItem={{ ...LayoutTypes[selectedLayout - 1], ...layoutItem }}
          showOnly={getItemByValue(metric?.showOnly)}
          periodValue={periodValue}
        />
      </div>
    );
  };

  // Handle drag start: Resize the item to smaller size (40%)
  const handleDragStart = (layout, oldItem, newItem) => {
    const layoutType = LayoutTypes[selectedLayout - 1];
    if (!layoutType) return;

    const { cols, partitions } = layoutType;

    const updatedLayout = layout.map((item) => {
      if (item.i === oldItem.i) {
        item.w = 0.3333 * cols; // Shrink to 40% size at the start of drag
      }
      return item;
    });
    setLayout(updatedLayout); // Update layout at the start of drag
  };

  const getNextItemLocation = (layout, column = 0) => {
    // Filter items that are in the specified column (x = column)
    const columnItems = layout.filter((item) => item.x == column);

    // Find the maximum y value among items in this column
    const maxY = columnItems.reduce(
      (max, item) => Math.max(max, item.y + item.h),
      0
    );

    // Return the new position for the next item
    return maxY;
  };

  const handleDrag = (currentLayout, oldItem, newItem, placeholder) => {
    const layoutType = LayoutTypes[selectedLayout - 1];
    if (!layoutType) return;

    const { cols, partitions } = layoutType;
    const threshold = Math.round(partitions[0] * cols);

    if (placeholder) {
      if (selectedLayout == 1 || selectedLayout == 4) {
        // 7-5 layout
        if (newItem.x < threshold) {
          placeholder.x = 0; // Snap to 60% zone
          placeholder.w = partitions[0] * cols; // Set width to 60%
        } else {
          placeholder.x = partitions[0] * cols; // Snap to 40% zone
          placeholder.w = partitions[1] * cols; // Set width to 40%
        }
      } else if (selectedLayout == 3) {
        // 7-5 layout
        if (newItem.x < threshold) {
          placeholder.x = 0; // Snap to 60% zone
          placeholder.w = partitions[0] * cols; // Set width to 60%
        } else {
          placeholder.x = partitions[0] * cols; // Snap to 40% zone
          placeholder.w = partitions[1] * cols; // Set width to 40%
        }
      } else if (selectedLayout == 2) {
        if (newItem.x < Math.round(partitions[0] * cols)) {
          placeholder.x = 0; // Snap to 60% zone
          placeholder.w = partitions[0] * cols; // Set width to 60%
        } else if (
          newItem.x < Math.round(partitions[0] * cols + partitions[1] * cols)
        ) {
          placeholder.x = partitions[0] * cols; // Snap to 40% zone
          placeholder.w = partitions[1] * cols; // Set width to 40%
        } else {
          placeholder.x = partitions[0] * cols + partitions[1] * cols; // Snap to 40% zone
          placeholder.w = partitions[2] * cols; // Set width to 40%
        }
      }
    }

    const updatedLayout = currentLayout.map((item) => {
      if (item.i === newItem.i) {
        item.x = newItem.x;
        item.w = 0.28 * cols;
      }
      return item;
    });

    setLayout(updatedLayout); // Update layout during drag
  };

  const handleDragStop = (currentLayout, oldItem, newItem) => {
    const layoutType = LayoutTypes[selectedLayout - 1];
    if (!layoutType) return;

    const { cols, partitions } = layoutType;

    const p0 = Math.round(partitions[0] * cols);
    const p1 = Math.round(partitions[1] * cols);

    const threshold = p0;
    let updatedLayout = currentLayout.map((item) => {
      if (item.i === newItem.i) {
        if (selectedLayout == 1) {
          // 50-50 layout
          if (newItem.x < threshold) {
            item.x = 0; // Snap to the left column
            item.w = Math.round(p0); // Set width to 50%
            item.h = calculateHeight(partitions[0], item.h);
          } else {
            item.x = p0; // Snap to the right column
            item.w = p1; // Set width to 50%
            item.h = calculateHeight(partitions[1], item.h);
          }
        } else if (selectedLayout == 2) {
          const p2 = partitions[2] * cols;
          // 3 equal parts
          if (newItem.x < threshold) {
            item.x = 0; // Snap to the first column
            item.w = p0; // Set width to 1/3
            item.h = calculateHeight(partitions[0], item.h);
          } else if (newItem.x < p0 + p1) {
            item.x = p0; // Snap to the second column
            item.w = p1; // Set width to 1/3
            item.h = calculateHeight(partitions[1], item.h);
          } else {
            item.x = p0 + p1; // Snap to the third column
            item.w = p2; // Set width to 1/3
            item.h = calculateHeight(partitions[2], item.h);
          }
        } else if (selectedLayout == 3) {
          // 7-5 layout
          if (newItem.x < threshold) {
            item.x = 0; // Snap to 60% zone
            item.w = p0; // Set width to 60%
            item.h = calculateHeight(partitions[0], item.h);
          } else {
            item.x = p0; // Snap to 40% zone
            item.w = p1; // Set width to 40%
            item.h = calculateHeight(partitions[1], item.h);
          }
        } else if (selectedLayout == 4) {
          // 5-7 layout
          if (newItem.x < threshold) {
            item.x = 0; // Snap to 40% zone
            item.w = p0; // Set width to 40%
            item.h = calculateHeight(partitions[0], item.h);
          } else {
            item.x = p0; // Snap to 60% zone
            item.w = p1; // Set width to 60%
            item.h = calculateHeight(partitions[1], item.h);
          }
        }
      }
      return item;
    });
    updatedLayout = findEmptyColumnAndAddConfig(updatedLayout, selectedLayout);
    setLayout(updatedLayout); // Apply the final layout on stop
  };

  const handleHotelChange = (
    hotelIds: any,
    subGroupSelectValue: any,
    hotelName: any
  ) => {
    setGroupEhid({
      hotelIds,
      hotelName,
      subGroupSelectValue,
    });
  };

  const columnWidth = getColumnWidth();

  // Memoize layouts
  const memoizedLayouts = useMemo(() => ({ lg: layout }), [layout]);

  const renderedMetrics = useMemo(() => {
    let _hids = groupEhid.hotelIds == 0 ? [] : groupEhid.hotelIds?.split(",");
    _hids = _hids?.map((item) => ({
      value: item,
      description: item,
      hotelId: item,
    }));

    const filledLayout = [...layout];

    return filledLayout.map((item, index) => {
      return {
        key: item.i,
        isSticky: item.i.startsWith("empty") ? true : false,
        content: item.i.startsWith("empty")
          ? renderEmptyState(item.x)
          : renderMetrics(metrics[index], _hids, item),
      };
    });
  }, [periodValue, groupEhid.hotelIds, layout]);

  // Memoize cols
  const memoizedCols = useMemo(
    () => ({
      lg: selectedLayout == 2 ? 12 : 12,
      md: selectedLayout == 2 ? 12 : 12,
      sm: 1,
      xs: 1,
      xxs: 1,
    }),
    [selectedLayout]
  );

  return (
    <Container fluid className="dashboard-portfolio-page">
      {/* Prompt to block navigation if there are unsaved changes */}
      <Prompt
        when={unsavedChanges}
        message={(location) => {
          // Confirm before navigating away
          console.log({ location });
          handleConfirm(location);
          return false; // Block navigation while waiting for the user's decision
        }}
      />
      <div className="page-heading page-header">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="title-badge">Portfolio Dashboard</h5>
            <div className="portfolio-selector">
              {!dashboardDataListLoading && DashboardDataList?.length > 0 && (
                <DashboardsListDropdown
                  data={DashboardDataList}
                  defaultValue={dashboardId}
                  placeholder="Select Dashboard"
                  onCreateDashboard={onCreateDashboard}
                  onItemSelected={handleSelection}
                />
              )}
            </div>
          </div>
          <div
            className={`right-navigation ${editModeOn ? "editing-mode" : ""}`}
          >
            {editModeOn ? (
              <>
                <Button
                  type="outline"
                  className="add-metric-btn"
                  onClick={() => handleAddMetric()}
                  disabled={
                    layout.filter((item) => !item.i.startsWith("empty"))
                      .length == 20
                  }
                >
                  Add Metric Card
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M13.5 9.75H9.75V13.5C9.75 13.9125 9.4125 14.25 9 14.25C8.5875 14.25 8.25 13.9125 8.25 13.5V9.75H4.5C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25H8.25V4.5C8.25 4.0875 8.5875 3.75 9 3.75C9.4125 3.75 9.75 4.0875 9.75 4.5V8.25H13.5C13.9125 8.25 14.25 8.5875 14.25 9C14.25 9.4125 13.9125 9.75 13.5 9.75Z"
                      fill="#2CAF92"
                    />
                  </svg>
                </Button>
                <HorizontalDropdownLayout
                  value={selectedLayout}
                  onSelectItem={(value) => handleCustomLayoutChange(value)}
                />

                <Button
                  type="outline"
                  variant={editModeOn ? "outline-success" : ""}
                  onClick={handleCancel}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!unsavedChanges}
                  onClick={() => handleSavePositions()}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button type="outline" variant="outline-success">
                  Share
                </Button>
                <Button onClick={handleEditButton}>Edit</Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="page-body">
        <div className="navigation-selectors">
          <div className="card-selectors">
            <div>
              <span className="label">Date Range</span>
              {!dashboardDataListLoading && Periods?.length > 0 && (
                <PeriodSelector
                  id={"chartPeriodSelector"}
                  defaultValue={periodValue}
                  onChange={(item) => setPeriodValue(item.value)}
                  periods={Periods}
                ></PeriodSelector>
              )}
            </div>
            <div className="ml-4 pl-3">
              <span className="label">Properties</span>
              {!dashboardDataListLoading && <HotelGroupSelector
                isDashboard={true}
                hotelIds={groupEhid.hotelIds}
                subGroupSelectValue={groupEhid.subGroupSelectValue}
                onHotelChange={handleHotelChange}
                notIncludeEnterpriseAccounts={true} // default Props
                showOnlyHotelAccounts={true} // default Props
                moduleName={moduleName} // default Props
                selectedLabel="Properties"
              ></HotelGroupSelector>}
            </div>
          </div>
        </div>

        {/* React Grid Layout for draggable and resizable Metric charts */}
        {metricsLoading ? (
          <></>
        ) : metrics?.length == 0 ? (
          <>{renderEmptyMetricNone()}</>
        ) : (
          <ResponsiveGridLayout
            // isDraggable={false}
            isDraggable={editModeOn}
            // isResizable
            isResizable={false}
            useCSSTransforms={false}
            allowOverlap={false}
            // isBounded={true}
            // preventCollision={true}
            // preventCollision={true}
            // compactType={null}
            className={`layout ${
              selectedLayout === 2 ? "three-col-view" : ""
            } ${editModeOn ? "layout-edit-mode" : ""}`}
            layouts={memoizedLayouts}
            breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
            cols={memoizedCols} // Define columns per breakpoint
            // rowHeight={rowHeight}
            onDrag={handleDrag}
            onDragStop={handleDragStop}
            onDragStart={handleDragStart}
            // onLayoutChange={(newLayout) => {
            //   console.log({ newLayout });
            //   handleLayoutChange(newLayout);
            // }}
          >
            {renderedMetrics.map(({ key, content, isSticky }) => (
              <div
                key={key}
                className={`${
                  isSticky ? "grid-item sticky-cell" : "grid-item"
                }`}
              >
                {content}
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </div>

      {showModal && (
        <MetricModal
          show={showModal}
          handleClose={hideModal}
          y={showModal ? getNextItemLocation(layout, modalXValue) : 0}
          x={modalXValue}
          handleSavePositions={handleSavePositions}
          dashboardId={dashboardId}
          unsavedChanges={unsavedChanges}
        />
      )}
    </Container>
  );
}

export default PortfolioDashboardPageLayout;
