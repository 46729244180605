import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";
import AddRenameModal from "./AddRenameModal";
import { resolve, reject } from "q";

interface DropDownListProps {
  data: any[]; // List of items to display
  placeholder?: string; // Placeholder text for the dropdown
  onItemSelected: (item: any) => void; // Callback when an item is selected
  onCreateDashboard: () => void;
}

const defaultItem = {
  dashboardName: "Untitled",
  description: "",
};

export const DashboardsListDropdown: React.FC<any> = ({
  data,
  placeholder = "Select an option",
  onItemSelected,
  defaultValue,
  onCreateDashboard,
}) => {
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [editItem, setEditItem] = useState<any | null>(null);
  const [filterData, setFilterData] = useState(data);
  const [inputValue, setInputValue] = useState("");
  const [mode, setMode] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setFilterData([...data]);
  }, [data]);

  useEffect(() => {
    if (selectedItem?.dashboardId != defaultValue) {
      const _selectedItem = data.find(
        (item) => item.dashboardId == defaultValue
      );

      setSelectedItem(_selectedItem);
    }
  }, [defaultValue]);

  // Handle dropdown search/filter
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const filteredItems = data.filter((item) =>
      item.label.toLowerCase().includes(value)
    );
    setFilterData(filteredItems);
    setInputValue(e.target.value);
  };

  // Handle selection of an item
  const handleItemSelect = (item: any) => {
    setSelectedItem(item);
    onItemSelected(item);
    setInputValue(""); // Reset search box
    setFilterData(data); // Reset filter
  };

  const handleAddRenameSave = (updatedItem: any) => {
    const requestData: any = {
      DashboardName: updatedItem.dashboardName,
      Description: updatedItem.description,
    };
    if (mode == "edit") {
      requestData.DashboardId = updatedItem.dashboardId;
      requestData.DashboardLayout = updatedItem.dashboardLayout;
      requestData.DefaultDaterange = updatedItem.defaultDaterange;
      requestData.DefaultProperties =  updatedItem.defaultProperties;
     
    } else if (mode == "add") {
      requestData.DashboardLayout = "3";
      requestData.DefaultDaterange = "4";
      requestData.DefaultProperties = "0";
    }
    setIsSubmitting(true);
    PortfolioDashboard.AddEditDashboardMaster(requestData)
      .then((res) => {
        if(res.success)
        {
          let message="Dashboard added successfully."
          if(res.result == "DashboardMaster updated successfully")
          {
            message="Dashboard updated successfully."
          }
          setShowRenameModal(false);
          toast.success(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        else{
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
       
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setMode(null);
        setEditItem(null);
        onCreateDashboard();
        setIsSubmitting(false);
      });
  };

  const handleDeleteConfirmation = (DashboardId) => {
    confirmAlert({
      title: "Delete Dashboard",
      message: "Are you sure you want to Delete Dashboard?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteDashboardMaster(DashboardId),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const handleDeleteDashboardMaster = (DashboardId: any) => {
    const requestData = {
      DashboardId: DashboardId,
    };
    PortfolioDashboard.DeleteDashboardMaster(requestData)
      .then((res) => {
        console.log(res);
        if(res.success){
          toast.success("Dashboard deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        else{
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        onCreateDashboard();
      });
  };

  const handleAddRenameClose = () => {
    setShowRenameModal(false);
    // setSelectedItem(null);
    setMode(null);
    setEditItem(null);
  };

  const handleRename = (item) => {
    setEditItem(item);
    setShowRenameModal(true);

    setMode("edit");
  };

  const handleCreateModal = (e) => {
    e.stopPropagation();
    setShowRenameModal(true);

    setMode("add");
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-custom-components"
          className="dropdown-toggle"
        >
          <EllipsisWithTooltip placement="top">
            {selectedItem ? selectedItem.dashboardName : placeholder}
          </EllipsisWithTooltip>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu">
          {/* Dropdown items */}
          {filterData.length > 0 ? (
            filterData.map((item, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => handleItemSelect(item)}
                className={item.disabled ? "disabled" : ""}
                disabled={item.disabled}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <EllipsisWithTooltip placement="top">
                      {item.dashboardName}
                    </EllipsisWithTooltip>
                    <div className="item-description">
                      <EllipsisWithTooltip placement="top">
                        {item.description}
                      </EllipsisWithTooltip>
                    </div>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRename(item);
                      }}
                    >
                      Rename
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirmation(item.dashboardId);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                  <Button disabled={item.isSystem == 1}  className="ml-auto">
                    <BsThreeDots />
                  </Button>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <div className="no-results">No results found</div>
          )}

          {/* "Create Dashboard" option */}
          <Dropdown.Item
            className="create-dashboard"
            onClick={handleCreateModal}
          >
            <span>Create Dashboard</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M13.5 9.75H9.75V13.5C9.75 13.9125 9.4125 14.25 9 14.25C8.5875 14.25 8.25 13.9125 8.25 13.5V9.75H4.5C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25H8.25V4.5C8.25 4.0875 8.5875 3.75 9 3.75C9.4125 3.75 9.75 4.0875 9.75 4.5V8.25H13.5C13.9125 8.25 14.25 8.5875 14.25 9C14.25 9.4125 13.9125 9.75 13.5 9.75Z"
                fill="#2CAF92"
              />
            </svg>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {mode && (
        <AddRenameModal
          mode={mode}
          show={showRenameModal}
          item={mode == "edit" ? editItem : defaultItem}
          onSave={handleAddRenameSave}
          onClose={handleAddRenameClose}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default DashboardsListDropdown;
