import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";
import MetricRightPanel from "../components/MetricRightPanel";
import _ from "lodash";

function searchItemsByTitle(items, searchTerm) {
  if (!Array.isArray(items)) {
    throw new Error("First argument must be an array");
  }
  if (typeof searchTerm !== "string") {
    throw new Error("Search term must be a string");
  }

  // Convert searchTerm to lowercase to make search case-insensitive
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return items.filter((item) => {
    // Check if item has a title property and it’s a string
    if (item && typeof item.paramDisplayName === "string") {
      // Convert title to lowercase and check for partial match
      return (
        item.paramDisplayName.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.paramDescrp.toLowerCase().includes(lowerCaseSearchTerm)
      );
    }
    return false;
  });
}

const dummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
function MetricModal({ show, handleClose, x = 0, y = 0, handleSavePositions , dashboardId,unsavedChanges}: any) {
  const [selectedMetric, setSelectedMetric] = useState<any>(null);
  const [metricList, setMetricList] = useState<any>([]);
  const [filteredMetricList, setFilteredMetricList] = useState<any>([]);
  const [metricListLoading, setMetricListLoading] = useState<any>(false);
  const [chartType, setChartType] = useState<any>(null); // default chart type
  const [searchTerm, setSearchTerm] = useState("");
  const [cardData, setCardData] = useState<any>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Store original values for each field
  const [originalValues, setOriginalValues] = useState<any>({
    chartTitle: selectedMetric?.paramDisplayName || "",
    chartComparison: [],
    showOnly: null,
  });

  useEffect(() => {
    setOriginalValues({
      chartTitle: selectedMetric?.paramDisplayName || "",
      chartComparison: [],
      showOnly: null,
    });
  }, [selectedMetric]);

  useEffect(() => {
    // Check if any field has unsaved changes, using deep comparison for arrays

    if (cardData) {
      setHasUnsavedChanges(
        cardData?.chartTitle !== originalValues?.chartTitle ||
          !_.isEqual(
            cardData?.chartComparison,
            originalValues?.chartComparison
          ) ||
          !_.isEqual(cardData?.showOnly, originalValues?.showOnly)
      );
    }
  }, [cardData, originalValues]);

  const UnsavedChanges = (callBack = () => {}) => {
    confirmAlert({
      title: "Unsaved Changes",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // Reset fields to original values
            callBack();
          },
        },
        {
          label: "No",
          onClick: () => setHasUnsavedChanges(true),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const getMertricList = () => {
    setMetricListLoading(true);
    PortfolioDashboard.GetDashboardParameters()
      .then((result) => {
        setMetricList(result);
        setFilteredMetricList(result);
      })
      .catch((err) => {
        setMetricList([]);
        setFilteredMetricList([]);
      })
      .finally(() => {
        setMetricListLoading(false);
      });
  };

  useEffect(() => {
    if (show) {
      getMertricList();
    }
  }, [show]);

  useEffect(() => {
    const results = searchItemsByTitle(metricList, searchTerm);
    setFilteredMetricList(results);
  }, [searchTerm]);

  const handleMetricSelect = (metric) => {
    // if (hasUnsavedChanges) {
    //   UnsavedChanges(() => {
    //     setSelectedMetric(metric);
    //     setChartType(metric.defaultType || null);
    //     setCardData(null);
    //     setHasUnsavedChanges(false);
    //   });
    // } else {
      setSelectedMetric(metric);
      setChartType(metric.defaultType || null);
      setCardData(null);
      setHasUnsavedChanges(false);
    // }
  };

  const resetData = () => {
    setSelectedMetric(null);
    setMetricList([]);
    setFilteredMetricList([]);
    setMetricListLoading(false);
    setChartType(null);
    setSearchTerm("");
    setCardData(null);
  };

  const closeModal = (isSave = false) => {
    if (!isSave && hasUnsavedChanges) {
      UnsavedChanges(() => {
        resetData();
        handleClose();
        setHasUnsavedChanges(false);
      });
    } else {
      resetData();
      handleClose(isSave);
      setHasUnsavedChanges(false);
    }

    // resetData();
    // handleClose();
  };

  const AddToDashboard=()=>{
    if (cardData) {
      const requestData = {
        DashboardId: dashboardId,
        Title: cardData?.chartTitle,
        CardId: cardData?.cardId,
        ColumnNo: Math.round(x),
        RowNo: Math.round(y) ,
        ComparisionCardId: cardData?.chartComparison?.map((item) => item.id),
        ShowOnly: cardData?.showOnly?.value,
      };

      setIsSubmitting(true);

      PortfolioDashboard.SaveDashboard(requestData)
        .then((result) => {
          setIsSubmitting(false);
          closeModal(true);
          if (result.success) {
            toast.success("Card added to Dashbboard.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(result.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.success("Something went Wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log(error);
        });
    }
  }

  const handleAddToDashboard = () => {
    // Call API
    if(unsavedChanges){
      handleSavePositions(()=>{AddToDashboard()})
    }
    else{
      AddToDashboard()
    }

    
   
  };

  console.log({ show });
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="xl"
      dialogClassName="modal-90w"
      className="dashboard-portfolio-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Metric Card Library</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3} className="border-end left-panel px-0">
            <div className="search-box">
              <Form.Control
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={50}
              />
            </div>
          </Col>
          <Col md={9} className="right-panel">
            <div className="right-panel-header">
              <h6 className="sub-heading">Configure</h6>
            </div>
          </Col>
        </Row>
        <Row className="chart-row">
          <Col md={3} className="border-end left-panel px-0">
            <ListGroup>
              {metricListLoading
                ? dummyList.map((item) => (
                    <ContentLoader
                      key={item}
                      viewBox="0 0 100% 120" // Adjusting the height in viewBox to 90
                      style={{
                        width: "100%",
                        height: "120px",
                        margin: "5px 0px",
                      }} // Setting fixed height to 90px
                    >
                      {/* Only SVG shapes */}
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="120"
                      />{" "}
                    </ContentLoader>
                  ))
                : filteredMetricList.length > 0 &&
                  filteredMetricList.map((metric) => (
                    <ListGroup.Item
                      key={metric.id}
                      action
                      onClick={() => handleMetricSelect(metric)}
                      active={metric?.id === selectedMetric?.id}
                    >
                      <h5 className="font-weight-bold">
                        {metric.paramDisplayName}
                      </h5>
                      <p className="text-muted">{metric.paramDescrp}</p>
                    </ListGroup.Item>
                  ))}
            </ListGroup>
          </Col>
          <Col
            md={9}
            className={`right-panel d-flex align-items-center justify-content-center ${
              selectedMetric ? "panel-bg" : ""
            }`}
          >
            {metricListLoading ? (
         
              <Spinner animation="border" variant="success" />
            ) : (
              <MetricRightPanel
                selectedMetric={selectedMetric}
                defaultChartType={chartType}
                setCardData={setCardData}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={
            !selectedMetric ||
            !cardData?.chartTitle ||
            (cardData.chartType?.toLowerCase() == "progress" &&
              cardData.chartComparison?.length == 0) ||
            isSubmitting
          }
          variant="primary"
          onClick={handleAddToDashboard}
        >
          Add to Dashboard
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MetricModal;
