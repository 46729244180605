import { Utils } from "../../../../Common/Utilis";

export const getCommonOptions = (formatType, metric, isDummyData) => {
  const YSymbol =
    formatType === "currency" ? "$" : formatType === "percentage" ? "%" : null;
  const YLabel = isDummyData
    ? ""
    : `${metric.paramName} ${YSymbol ? `(${YSymbol})` : ""}`;
  const XLabel = "Property";

  return {
    responsive: true,
    maintainAspectRatio:false,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        yAlign: "top", // Align tooltip to the top

        callbacks: {
          label: (tooltipItem) => {
            let label = tooltipItem.dataset.label || "";
            if (label) {
              label += ": ";
            }
            const value = tooltipItem.raw;

            // Format based on formatType
            if (formatType === "currency") {
              label += Utils.currencyFormat(Number(value || 0).toFixed(0));
            } else if (formatType === "percentage") {
              label += `${value}%`;
            } else {
              label += value; // Default numeric format
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          // color: "#F4F6F8"
        },
        title: {
          display: true,
          text: XLabel,
          font: { size: 12 },
          color: "#84888C",
        },
      },
      y: {
        grid: { color: "#F4F6F8" },
        beginAtZero: true,
        title: {
          display: true,
          text: YLabel,
          font: { size: 12 },
          color: "#84888C",
        },
        ticks: {
          callback: function (value) {
            if (formatType === "currency") {
              if (value >= 1e9) {
                return `${(value / 1e9).toFixed(1)}B`; // Billions
              } else if (value >= 1e6) {
                return `${(value / 1e6).toFixed(1)}M`; // Millions
              } else if (value >= 1e3) {
                return `${(value / 1e3).toFixed(1)}K`; // Thousands
              }
              return `${value}`; // Default
            }
            return value; // Default numeric format
          },
        },
      },
    },
  };
};
