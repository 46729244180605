import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";


const defaultItem = { dashboardName: "", description: "" };

const AddRenameModal: React.FC<any> = ({
  show = false,
  item = defaultItem,
  onSave,
  onClose,
  mode,
  isSubmitting
}) => {
  const [name, setName] = useState(item?.dashboardName || "");
  const [description, setDescription] = useState(item?.description || "");
  const [inputPosition, setInputPosition] = useState(0);
  const [error, setError] = useState("");

  const handleSave = () => {
    onSave({ ...item, dashboardName: name, description });
  };

  const handleBlur = (inputPos) => {
    if(inputPos == 1){
      setName((prev) => prev.trim());
      if (name.trim() === "") {
        setError("Please enter a dashboard name");
      } else {
        setError("");
      }
    }
  
    

    setInputPosition(inputPos)
  };


  const content = (
    <>
      <Form>
        <Form.Group controlId="dashboardName">
          <Form.Label>Dashboard Name <span>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Dashboard Name"
            value={name}
            onChange={(e) => {setName(e.target.value)
              setError("");
            }}
            tabIndex={1}
            maxLength={50}
            className={error ? "error-invalid" : ""}
            onBlur={()=>handleBlur(1)}
          />
           {error && inputPosition >=1 && <div className="error-message">{error}</div>}
        </Form.Group>
        <Form.Group controlId="dashboardDescription" className="mt-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            tabIndex={2}
            onBlur={()=>handleBlur(2)}
          />
        </Form.Group>
      </Form>
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outline" onClick={onClose} tabIndex={3}>
          Cancel
        </Button>
        <Button disabled={!name || isSubmitting}  onClick={handleSave} className="ms-2" tabIndex={4}>
          Save
        </Button>
      </div>
    </>
  );

  return mode === "add" ? (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{mode === "add" ? "Create Dashboard" : "Edit Dashboard"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  ) : (
    <div className="p-3 border rounded">
      <h4>{mode == "add" ? "Create Dashboard" : "Edit Dashboard"}</h4>
      {content}
    </div>
  );
};

export default AddRenameModal;
